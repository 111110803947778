<template>
    <div class="container">
        <div class="header">
            <img class="image" src="@/assets/images/start/logo.svg" />
            <div class="top-button" @click="goRegister">注册</div>
        </div>
        <div class="video-con">
            <img class="mobile-bg" src="@/assets/images/start/mobile-bg.jpg" alt="">
            <video id="videoBtn" autoplay loop muted src="@/assets/images/start/bg.mp4"
                x5-video-player-fullscreen="true" x5-playsinline playsinline webkit-playsinline></video>
            <div class="head-box">
                <img class="logo" src="@/assets/images/start/logo-white.png" alt="">
                <span class="top-button" @click="goRegister">注册</span>
            </div>
            <div class="content">
                <span class="fs-42 lar-title">YinoCloud易诺云</span>
                <span class="fs-25">一站式海外广告智能管理系统</span>
                <span class="fs-16 desc">快速跑通出海营销链路，提升广告投放能力，实现效益增长</span>
            </div>
            <div class="center-button" @click="goRegister">
                <span>注册</span>
                <span>注册</span>
            </div>
            <div class="next-button" @click="goSecondSection"></div>
        </div>
    </div>
    <div class="flex-col page">
        <div class="flex-col group_3">
            <span class="self-center font_2">YinoCloud易诺云</span>
            <span class="self-center font_4 text_5">
                YinoCloud易诺云集合海外广告账户开户与充值、素材制作、广告情报数据、广告效果自动优化工具、海外营销知识培训等服务与功能，帮助出海广告主快速跑通海外营销链路，提升出海投放能力，实现效益增长。
            </span>
            <div class="register-button" @click="goRegister">注册开户
                <img src="@/assets/images/start/arrow-right.svg" alt="">
            </div>
            <div class="flex-col justify-start self-center text-wrapper_2">
                <div class="font_5 text_7">注册认证后将有专业顾问1v1为您解答出海营销问题。</div>
            </div>
            <div class="flex-row self-center group_4">
                <img class="shrink-0 image_4" src="@/assets/images/start/two-section-left.png" />
                <span class="ml-4 flex-1 font_5 text_8">超10000+ 出海广告主使用YinoCloud易诺云，立即加入吧！</span>
            </div>
            <img class="self-center image_5" src="@/assets/images/start/two-section-right.png" />
            <div class="flex-col items-center self-stretch group_5">
                <span class="font_2 text_9">我们能做什么</span>
                <span class="font_3 mt-21">Core Services</span>
            </div>
            <div class="flex-col self-center group_6">
                <div class="flex-col self-center section_3">
                    <div class="flex-col justify-start items-center self-stretch">
                        <el-carousel height="260px" class="el-carousel" arrow="never" @change="setIndex">
                            <el-carousel-item key="1">
                                <img src="@/assets/images/start/mobile-swiper1.png" alt="">
                            </el-carousel-item>
                            <el-carousel-item key="2">
                                <img src="@/assets/images/start/mobile-swiper2.png" alt="">
                            </el-carousel-item>
                            <el-carousel-item key="3">
                                <img src="@/assets/images/start/mobile-swiper3.png" alt="">
                            </el-carousel-item>
                            <el-carousel-item key="4">
                                <img src="@/assets/images/start/mobile-swiper4.png" alt="">
                            </el-carousel-item>
                            <el-carousel-item key="5">
                                <img src="@/assets/images/start/mobile-swiper5.png" alt="">
                            </el-carousel-item>
                            <el-carousel-item key="6">
                                <img src="@/assets/images/start/mobile-swiper6.png" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <span class="self-center text_10" v-show="index === 0">广告开户</span>
                    <span class="self-stretch font_5 text_11" v-show="index === 0">
                        拥有Facebook、TikTok、Bing、Google等海外主流社交媒体代理资源，注册后简单认证即可开通账户，最快当天下户
                    </span>
                    <span class="self-center text_10" v-show="index === 1">账户管理</span>
                    <span class="self-stretch font_5 text_11" v-show="index === 1">
                        支持人民币美金双币多平台充值方式、广告账户像素绑定/解绑、自助余额清零、广告投放数据以及成效分析
                    </span>
                    <span class="self-center text_10" v-show="index === 2">素材模板</span>
                    <span class="self-stretch font_5 text_11" v-show="index === 2"
                        style="width: 100%;text-align: center;margin: 10px 0;">
                        创意素材一键套用，批量制作省心省力
                    </span>
                    <span class="self-center text_10" v-show="index === 3">跨境情报</span>
                    <span class="self-stretch font_5 text_11" v-show="index === 3">
                        联合跨境业内优质数据类合作伙伴，为出海广告主提供素材、流量、选品等全球跨境市场情报
                    </span>
                    <span class="self-center text_10" v-show="index === 4">广告开户</span>
                    <span class="self-stretch font_5 text_11" v-show="index === 4">
                        拥有Facebook、TikTok、Bing、Google等海外主流社交媒体代理资源，注册后简单认证即可开通账户，最快当天下户
                    </span>
                    <span class="self-center text_10" v-show="index === 5">知识培训</span>
                    <span class="self-stretch font_5 text_11" v-show="index === 5">
                        为不同阶段、不同市场、不同业务的中国出海卖家提供全面而深入的海外营销知识体系支持
                    </span>
                </div>
                <div class="register-button" @click="goRegister">注册开户
                    <img src="@/assets/images/start/arrow-right.svg" alt="">
                </div>
            </div>
            <div class="flex-col self-center group_7">
                <span class="self-center font_2 text_12">赋能效果增长</span>
                <div class="flex-col self-center mt-21">
                    <span class="self-center font_3">Effect and Profit</span>
                    <div class="mt-24 flex-col self-center section_5">
                        <span class="font_4 text_13 self-center">
                            YinoCloud易诺云集合海外广告账户开户与充值、素材制作、广告情报数据、广告效果自动优化工具、海外营销知识培训等服务与功能，帮助出海广告主快速跑通海外营销链路，提升出海投放能力，实现效益增长。
                        </span>
                        <div class="flex-col mt-23">
                            <div class="flex-col justify-start self-stretch relative group_8 ">
                                <!-- <div class="section_5"></div> -->
                                <div class="flex-col pos">
                                    <div class="flex-row items-start mt-20 list-item">
                                        <img class="shrink-0 image_7"
                                            src="@/assets/images/start/four-section-yxgh.png" />
                                        <div class="ml-16 flex-col flex-1 group_9">
                                            <span class="self-start font_6 text_14">营销干货</span>
                                            <span class="self-stretch font_7 text_15 mt-13">
                                                不断更新跨境行业市场洞察、投放攻略、案例解读等文档指南，内容涵盖Facebook、TikTok、Bing、Google等主流媒体，行业包括跨境电商、APP出海、游戏出海
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex-row items-start mt-20 list-item">
                                        <img class="shrink-0 image_7"
                                            src="@/assets/images/start/four-section-kcyhd.png" />
                                        <div class="ml-16 flex-col flex-1 group_9">
                                            <span class="self-start font_6 text_14">课程与活动</span>
                                            <span class="self-stretch font_7 text_15 mt-13">
                                                专业海外广告投放团队系列精品课程、系列广告直播课，定期邀请行业内专业讲师、优质卖家、官方直客参与线上直播或线下沙龙，为出海广告主持续输出最新市场趋势，解答营销问题
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex-row items-start mt-20 list-item">
                                        <img class="shrink-0 image_7"
                                            src="@/assets/images/start/four-section-zsgw.png" />
                                        <div class="ml-16 flex-col flex-1 group_9">
                                            <span class="self-start font_6 text_14">专属顾问</span>
                                            <span class="self-stretch font_7 text_15 mt-13">
                                                注册认证后对接专属商务协助解决开户问题，3年以上海外营销资质优化师进群指导，账户投放问题与方案随时咨询
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="register-button self-center" @click="goRegister">注册/登录
                            <img src="@/assets/images/start/arrow-right.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-col section_7">
            <div class="self-center bottom-logo"><img class="image" src="@/assets/images/start/logo.svg" /></div>
            <div class="flex-col self-center group_11">
                <span class="self-start font_6 text_21">YinoCloud易诺云</span>
                <span class="mt-12 self-start text_22">一站式海外广告智能管理系统</span>
                <div class="mt-12 flex-col self-stretch">
                    <span class="self-center font_5 text_23">
                        YinoCloud易诺云是YinoLink易诺旗下的一站式海外广告智能管理系统，包含多个高效管理模块，为出海营销卖家提供海外广告账户开户与充值、素材制作、广告情报数据、广告效果自动优化工具、海外营销知识培训等服务与功能，帮助出海广告主快速跑通海外营销链路，提升出海投放能力，实现效益增长。
                    </span>
                    <img class="mt-24 self-center image_9" :src="wlQrcode" />
                </div>
            </div>
            <span class="self-center font_4 text_24">企业微信二维码</span>
            <div class="flex-col self-center group_12">
                <div class="flex-row justify-between group_13">
                    <router-link to="/hc" tag="a" target="_blank"><span>帮助</span></router-link>
                    <el-link href="https://yinocloud.yinolink.com/privacy.html" :underline="false"
                        target="_blank"><span>隐私</span></el-link>
                    <el-link :underline="false" href="https://yinocloud.yinolink.com/service.html"
                        target="_blank"><span>条款</span></el-link>
                </div>
                <div class="flex-col group_14 mt-25">
                    <span class="font_7 text_28">杭州益刻网络科技有限公司版权所有Copyright © 2020</span>
                    <div class="flex-row justify-center items-center mt-11">
                        <img class="image_10" src="@/assets/officeIcon.png" />
                        <span class="font text_29 ml-9">沪ICP备20011628号-1</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, onUnmounted } from 'vue'
import baidu from '@/base/baidu-tracking.js'

const { proxy } = getCurrentInstance()
const wlQrcode = ref(null);
const index = ref(0);

const setIndex = (val) => {
    index.value = val;
}

const goRegister = () => {
    proxy.$router.push({ path: '/register', query: { source: 'YCGWmob' } })
}

//用户滚动屏幕播放视频
document.addEventListener('scroll', function () {
    var video = document.getElementById("videoBtn");
    video.style.display = "block";
    video.play();
}, false);

; (function () {
    let wl = document.createElement("script")
    wl.type = "text/javascript"
    wl.src = "https://material.weiling.cn/h5/willing-third-party-js/v1/qrcode-custom-comp-v1.min.js"
    wl.id = "wlQRCS"
    wl.onload = function () {
        wlQrcodeCustomComp.init({
            wl_id: "wpW2WmDAAAjT9PP65h63Ilm7Xlbvg4qg" // [修改1：将wl_id粘贴至此]
        })
    }
    if (!document.getElementById("wlQRCS")) {
        let s = document.getElementsByTagName("script")[0]
        s.parentNode.insertBefore(wl, s)
    }
})()
window.onload = function () {
    wlQrcodeCustomComp.getQrCodeUrl(
        {
            wl_qrcode_id: "1764898881298268160" //  [修改1：将wl_qrcode_id粘贴至此]
        },
        (data) => {
            // data中包含二维码图片地址
            if (data && data.code === 200) {
                wlQrcode.value = data.data.qr_code
            }
        }
    )
}

const goSecondSection = () => {
    document.querySelector('.page').scrollIntoView({ behavior: 'smooth' })
}

onMounted(() => {
    if (window.innerWidth > 1200) {
        proxy.$router.push('/start')
        return
    }

    // 监听滚动，有滚动让header显示，否则隐藏
    let timer = null;
    window.addEventListener('scroll', () => {
        clearTimeout(timer);
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let header = document.querySelector('.header');
        if (scrollTop > 0) {
            header.style.top = '0';
            timer = setTimeout(() => {
                header.style.top = '-80px';
            }, 2000);
        } else {
            header.style.top = '-80px';
        }
    })
})


onUnmounted(() => {
    window.removeEventListener('scroll', () => { })
})
</script>

<style scoped lang="less">
@media screen and (min-width: 375px) {
    .text_5 {
        min-width: 340px !important;
        width: 70vw !important;
    }

    .text-wrapper_2 {
        min-width: 340px !important;
        width: 70vw !important;
    }

    .text_13 {
        width: 100% !important;
    }

    .text_23 {
        min-width: 340px !important;
        width: 70vw !important;
    }

    .group_13 {
        min-width: 340px !important;
        width: 70vw !important;
    }

    .group_7 {
        min-width: 340px !important;
        width: 70vw !important;
    }

    .bottom-logo {
        min-width: 340px !important;
        width: 70vw !important;
    }

    .section_3 {
        min-width: 340px !important;
        width: 70vw !important;

        &>div:first-child {
            height: 70% !important;
        }

        .el-carousel {
            min-height: 260px !important;
            height: 100% !important;

            :deep(.el-carousel__container) {
                min-height: 260px !important;
                height: 100% !important;
            }
        }

        .text_10 {
            margin-top: 24px !important;
        }

        .text_11 {
            margin-top: 16px !important;
        }
    }

    .content>span:first-child {
        font-size: 11vw !important;
        margin-bottom: 0 !important;
    }

    .content>span:nth-child(2) {
        font-size: 6vw !important;
    }

    .content>span:nth-child(3) {
        width: 78vw !important;
        font-size: 3vw !important;
    }
}

@media screen and (min-width: 415px) {
    .text_7 {
        width: 415px !important;
    }
}

@media screen and(min-width: 820px) {
    .section_3 {
        border-radius: 20px !important;
        aspect-ratio: 5 / 4;

        .text_10 {
            margin-top: 24px !important;
            font-size: 24px !important;
        }

        .text_11 {
            margin-top: 16px !important;
            font-size: 16px !important;
        }
    }
}

* {
    padding: 0;
    margin: 0;
    z-index: 1;
}

body,
html {
    width: 100vw;
    height: 100vh;
    transition: all .5s ease-in-out;
    color: #333333;
    background-color: #F2F3F9;
    font-family: 'PingFang SC-Regular';
}

.fs-42 {
    font-size: 42px;
}

.fs-25 {
    font-size: 25px;
}

.fs-16 {
    font-size: 16px;
}

.mt-27 {
    margin-top: 27px;
}

.mt-21 {
    margin-top: 21px;
}

.mt-23 {
    margin-top: 23px;
}

.mt-13 {
    margin-top: 13px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-11 {
    margin-top: 11px;
}

.ml-9 {
    margin-left: 9px;
}

.page {
    background-color: #f2f3f9;
    width: 100%;
    overflow: hidden;

    .section {
        padding: 15px 20px 42px;

        .group {
            margin-top: 82px;
            padding: 0 2px;

            .text_2 {
                color: #ffffff;
            }

            .group_2 {
                margin-left: 4px;

                .text_3 {
                    color: #ffffff;
                    font-size: 25px;
                    line-height: 23.5px;
                }

                .text_4 {
                    color: #ffffff;
                    font-weight: 300;
                    text-align: justify;
                }
            }
        }

        .button_2 {
            align-self: center;
            margin-top: 38px;
        }

        .image_2 {
            margin-top: 36px;
            border-radius: 14px;
            width: 22px;
            height: 32px;
        }
    }

    .group_3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 64px 20px 42px;

        .register-button {
            width: 155px;
            height: 40px;
            background: #2B82FF;
            border-radius: 213px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 20px;

            img {
                width: 14px;
                height: 14px;
                vertical-align: middle;
            }

            &:hover {
                background: #333333;
                transition: all 0.8s;

                img {
                    transform: translateX(10px);
                    transition: all 0.8s;
                }
            }
        }

        .text_5 {
            width: 340px;
            margin-top: 22px;
            text-align: justify;
        }

        .section_2 {
            align-self: center;
            margin-top: 20px;
        }

        .text-wrapper_2 {
            position: relative;
            margin-top: 20px;
            padding-bottom: 14px;
            border-bottom: solid 1px #dddddd;

            .text_7 {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 340px;
                color: #999999;
                letter-spacing: 3px;
                text-align: justify;
            }
        }

        .group_4 {
            margin-top: 18px;

            .image_4 {
                margin: 2px 0;
                width: 121px;
                height: 40px;
            }

            .text_8 {
                width: 210px;
                text-align: justify;
            }
        }

        .image_5 {
            margin-top: 18px;
            width: 335px;
            height: 330px;
        }

        .group_5 {
            margin-top: 64px;

            .text_9 {
                line-height: 39px;
            }
        }

        .group_6 {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;

            .section_3 {
                padding-bottom: 12px;
                background-color: #ffffff;
                border-radius: 8px;
                overflow: hidden;
                width: 335px;

                .el-carousel {
                    width: 100%;
                    min-height: 260px;
                    overflow: hidden;

                    .el-carousel__item {
                        img {
                            width: 100%;
                            height: 100%;
                            min-height: 260px;
                            ;
                        }
                    }

                    :deep(.el-carousel__button) {
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: #ffffff
                    }

                    :deep(.is-active .el-carousel__button) {
                        background-color: #2B82FF;
                    }
                }
            }

            .text_10 {
                margin-top: 12px;
                color: #333333;
                font-size: 22px;
                font-family: PingFang SC;
                line-height: 21px;
            }

            .text_11 {
                margin: 10px 20px 0;
                text-align: justify;
            }
        }
    }

    .section_5 {
        background: url('~@/assets/images/start/four-section-right.png') no-repeat;
        background-size: 335px 276px;
        background-position: 100% 100%;
        // height: 276px;
    }

    .group_7 {
        width: 340px;
        margin-top: 64px;

        .text_12 {
            line-height: 39px;
        }

        .text_13 {
            width: 340px;
            text-align: justify;
        }

        .group_8 {

            .pos {
                position: relative;
                left: 0;
                right: 0;
                top: 0;

                .list-item {
                    padding: 16px 20px;
                    background-image: linear-gradient(180deg, #ffffff 0%, #ffffff66 100%);
                    border-radius: 8px;

                    &:first-child {
                        margin-top: 0;
                    }

                    .image_7 {
                        width: 40px;
                        height: 40px;
                    }

                    .group_9 {
                        margin-top: 10px;

                        .text_14 {
                            line-height: 18.5px;
                        }

                        .text_15 {
                            text-align: justify;
                        }
                    }
                }
            }
        }
    }

    .section_4 {
        align-self: center;
    }
}

.font_2 {
    font-size: 42px;
    font-family: PingFang SC;
    line-height: 39px;
    color: #333333;
}

.font_4 {
    font-size: 16px;
    font-family: PingFang SC;
    line-height: 24px;
    color: #333333;
}

.font_3 {
    font-size: 24px;
    font-family: PingFang SC;
    line-height: 18px;
    color: #666666;
}

.section_7 {
    padding: 40px 20px 42px;
    background-color: #101a29;
    align-items: center;

    .bottom-logo {
        width: 340px;
    }

    .group_11 {
        margin-top: 20px;

        .text_21 {
            color: #ffffff;
            line-height: 18.5px;
        }

        .text_22 {
            color: #ffffff;
            font-size: 20px;
            font-family: PingFang SC;
            line-height: 19px;
        }

        .text_23 {
            width: 340px;
            color: rgba(255, 255, 255, 0.8);
            text-align: justify;
        }

        .image_9 {
            border-radius: 8px;
            width: 120px;
            height: 120px;
        }
    }

    .text_24 {
        margin-top: 20px;
        color: #ffffff;
        line-height: 15px;
    }

    .group_12 {
        margin-top: 42px;
        padding-top: 22px;
        border-top: solid 1px #212a38;

        .group_13 {
            width: 340px;
            padding-left: 40px;
            padding-right: 38px;

            .text_25 {
                line-height: 13px;
            }

            .text_26 {
                line-height: 13px;
            }

            .text_27 {
                line-height: 13px;
            }

            a {
                text-decoration: none;
            }

            span {
                font-family: 'PingFang SC-Regular';
                font-size: 14px;
                color: #FFFFFF;
            }
        }

        .group_14 {
            text-align: center;

            .text_28 {
                color: #ffffff;
                line-height: 12.5px;
            }

            .image_10 {
                width: 16px;
                height: 16px;
            }

            .text_29 {
                line-height: 13px;
            }
        }

        .font {
            font-size: 14px;
            font-family: PingFang SC;
            line-height: 13px;
            color: #ffffff;
        }
    }
}

.image {
    width: 132px;
    height: 24px;
}

.font_6 {
    font-size: 20px;
    font-family: PingFang SC;
    line-height: 18px;
    color: #333333;
}

.font_5 {
    font-size: 14px;
    font-family: PingFang SC;
    line-height: 22px;
    color: #666666;
}

.font_7 {
    font-size: 12px;
    font-family: PingFang SC;
    line-height: 20px;
    color: #666666;
}

/************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/

html {
    font-size: 16px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Microsoft Yahei', sans-serif;
}

body * {
    box-sizing: border-box;
    flex-shrink: 0;
}

#app {
    width: 100vw;
    height: 100vh;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-stretch {
    align-items: stretch;
}

.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: flex-end;
}

.self-center {
    align-self: center;
}

.self-baseline {
    align-self: baseline;
}

.self-stretch {
    align-self: stretch;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-auto {
    flex: 1 1 auto;
}

.grow {
    flex-grow: 1;
}

.grow-0 {
    flex-grow: 0;
}

.shrink {
    flex-shrink: 1;
}

.shrink-0 {
    flex-shrink: 0;
}

.relative {
    position: relative;
}

.ml-2 {
    margin-left: 2px;
}

.mt-2 {
    margin-top: 2px;
}

.ml-4 {
    margin-left: 4px;
}

.mt-4 {
    margin-top: 4px;
}

.ml-6 {
    margin-left: 6px;
}

.mt-6 {
    margin-top: 6px;
}

.ml-8 {
    margin-left: 8px;
}

.mt-8 {
    margin-top: 8px;
}

.ml-10 {
    margin-left: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.ml-12 {
    margin-left: 12px;
}

.mt-12 {
    margin-top: 12px;
}

.ml-14 {
    margin-left: 14px;
}

.mt-14 {
    margin-top: 14px;
}

.ml-16 {
    margin-left: 16px;
}

.mt-16 {
    margin-top: 16px;
}

.ml-18 {
    margin-left: 18px;
}

.mt-18 {
    margin-top: 18px;
}

.ml-20 {
    margin-left: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.ml-22 {
    margin-left: 22px;
}

.mt-22 {
    margin-top: 22px;
}

.ml-24 {
    margin-left: 24px;
}

.mt-24 {
    margin-top: 24px;
}

.ml-26 {
    margin-left: 26px;
}

.mt-26 {
    margin-top: 26px;
}

.ml-28 {
    margin-left: 28px;
}

.mt-28 {
    margin-top: 28px;
}

.ml-30 {
    margin-left: 30px;
}

.mt-30 {
    margin-top: 30px;
}

.ml-32 {
    margin-left: 32px;
}

.mt-32 {
    margin-top: 32px;
}

.ml-34 {
    margin-left: 34px;
}

.mt-34 {
    margin-top: 34px;
}

.ml-36 {
    margin-left: 36px;
}

.mt-36 {
    margin-top: 36px;
}

.ml-38 {
    margin-left: 38px;
}

.mt-38 {
    margin-top: 38px;
}

.ml-40 {
    margin-left: 40px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-42 {
    margin-left: 42px;
}

.mt-42 {
    margin-top: 42px;
}

.ml-44 {
    margin-left: 44px;
}

.mt-44 {
    margin-top: 44px;
}

.ml-46 {
    margin-left: 46px;
}

.mt-46 {
    margin-top: 46px;
}

.ml-48 {
    margin-left: 48px;
}

.mt-48 {
    margin-top: 48px;
}

.ml-50 {
    margin-left: 50px;
}

.mt-50 {
    margin-top: 50px;
}

.ml-52 {
    margin-left: 52px;
}

.mt-52 {
    margin-top: 52px;
}

.ml-54 {
    margin-left: 54px;
}

.mt-54 {
    margin-top: 54px;
}

.ml-56 {
    margin-left: 56px;
}

.mt-56 {
    margin-top: 56px;
}

.ml-58 {
    margin-left: 58px;
}

.mt-58 {
    margin-top: 58px;
}

.ml-60 {
    margin-left: 60px;
}

.mt-60 {
    margin-top: 60px;
}

.ml-62 {
    margin-left: 62px;
}

.mt-62 {
    margin-top: 62px;
}

.ml-64 {
    margin-left: 64px;
}

.mt-64 {
    margin-top: 64px;
}

.ml-66 {
    margin-left: 66px;
}

.mt-66 {
    margin-top: 66px;
}

.ml-68 {
    margin-left: 68px;
}

.mt-68 {
    margin-top: 68px;
}

.ml-70 {
    margin-left: 70px;
}

.mt-70 {
    margin-top: 70px;
}

.ml-72 {
    margin-left: 72px;
}

.mt-72 {
    margin-top: 72px;
}

.ml-74 {
    margin-left: 74px;
}

.mt-74 {
    margin-top: 74px;
}

.ml-76 {
    margin-left: 76px;
}

.mt-76 {
    margin-top: 76px;
}

.ml-78 {
    margin-left: 78px;
}

.mt-78 {
    margin-top: 78px;
}

.ml-80 {
    margin-left: 80px;
}

.mt-80 {
    margin-top: 80px;
}

.ml-82 {
    margin-left: 82px;
}

.mt-82 {
    margin-top: 82px;
}

.ml-84 {
    margin-left: 84px;
}

.mt-84 {
    margin-top: 84px;
}

.ml-86 {
    margin-left: 86px;
}

.mt-86 {
    margin-top: 86px;
}

.ml-88 {
    margin-left: 88px;
}

.mt-88 {
    margin-top: 88px;
}

.ml-90 {
    margin-left: 90px;
}

.mt-90 {
    margin-top: 90px;
}

.ml-92 {
    margin-left: 92px;
}

.mt-92 {
    margin-top: 92px;
}

.ml-94 {
    margin-left: 94px;
}

.mt-94 {
    margin-top: 94px;
}

.ml-96 {
    margin-left: 96px;
}

.mt-96 {
    margin-top: 96px;
}

.ml-98 {
    margin-left: 98px;
}

.mt-98 {
    margin-top: 98px;
}

.ml-100 {
    margin-left: 100px;
}

.mt-100 {
    margin-top: 100px;
}

.container {

    .video-con {
        width: 100%;
        height: 100vh;
        /**no */
        position: relative;

        .top-button {
            width: 95px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 152px;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-family: 'PingFang SC-Regular';
            color: #333333;

            &:hover {
                background: #2b82ff;
                color: #ffffff;
                transition: all 0.5s;
            }
        }

        .content {
            position: absolute;
            top: 36%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #FFFFFF;
            position: relative;
            z-index: 2;

            .lar-title {
                margin-bottom: 16px;
            }

            .desc {
                width: 325px;
                font-weight: 300;
                font-size: 16px;
                color: #FFFFFF;
                margin-top: 8px;
                opacity: .8;
            }
        }

        .center-button {
            position: absolute;
            left: 50%;
            bottom: 180px;
            transform: translateX(-50%);
            width: 95px;
            height: 32px;
            border: 1px solid #FFFFFF;
            background: transparent;
            border-radius: 152px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-family: 'PingFang SC-Regular';
            font-weight: 500;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            span:nth-child(1) {
                line-height: 32px;
                position: absolute;
                top: 0;
                transition: all 1s;
            }

            span:nth-child(2) {
                position: absolute;
                top: 32px;
                transition: all 1s;
            }

            &:hover span:nth-child(1) {
                top: -32px;
            }

            &:hover span:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .next-button {
            position: absolute;
            left: 50%;
            bottom: 110px;
            transform: translateX(-50%);
            width: 22px;
            height: 36px;
            border-radius: 14px;
            border: 2px solid #505051;

            &:after {
                position: absolute;
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-color: #ffffff;
                animation: jump 2s infinite;
            }

            @keyframes jump {
                0% {
                    top: 30%;
                }

                50% {
                    top: 70%;
                }

                100% {
                    top: 30%;
                }
            }
        }

        .head-box {
            width: 100%;
            height: 60px;
            display: flex;
            position: relative;
            z-index: 2;
            padding: 0 20px;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;

            .logo {
                width: 132px;
                height: 24px;
            }

            .right-btn {
                display: block;
                background-color: white;
                font-size: 14px;
                width: 95px;
                line-height: 32px;
                border-radius: 20px;
                text-align: center;
                height: 32px;
            }
        }

        video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            z-index: 0;
            display: none;
        }

        .mobile-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            z-index: 0;
        }
    }

    .header {
        box-sizing: border-box;
        position: fixed;
        top: -80px;
        left: 0;
        height: 60px;
        width: 100vw;
        background-color: #ffffff;
        z-index: 9999;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        transition: all .8s;
        border-bottom: 1px solid #dcdfe6;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);


        .image {
            width: 132px;
            height: 24px;
        }

        .top-button {
            width: 95px;
            height: 32px;
            border-radius: 152px;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-family: 'PingFang SC-Regular';
            background: #2b82ff;
            color: #ffffff;
        }

    }

}
</style>